/**
 * @format
 */

export const onboardingStepper = stepperSelector => {
  const OPTIONS_SELECTOR = '.js-preferences-options';
  const OPTION_SELECTOR = '.js-preference-option';
  const $stepper = document.querySelector(stepperSelector);

  if (!$stepper) return;

  const $prevStepBtn = $stepper.querySelector('.js-swiper-button-prev');
  const $nextStepBtn = $stepper.querySelector('.js-swiper-button-next');
  const $submitBtn = $stepper.querySelector('.js-submit-btn');
  const paginationFormat = $stepper.dataset.paginationFormat;

  /**
   * Checks and sets pagination buttons state
   */
  const handlePaginationButtonState = () => {
    const $activeStep = swiperInstance.slides[swiperInstance.activeIndex];
    const $stepOptions = $activeStep && $activeStep.querySelector(OPTIONS_SELECTOR);
    const hasOptions = Boolean($stepOptions);
    const nextBtnEnabled = !hasOptions || (hasOptions && hasAllRequiredOptionsSelected($stepOptions));

    // Set next button text
    $nextStepBtn.innerText = swiperInstance.isBeginning ? $nextStepBtn.dataset.startText : $nextStepBtn.dataset.nextText;

    // Hide prev step button if current step is the first step
    $prevStepBtn.setAttribute('aria-hidden', swiperInstance.isBeginning);

    // Hide next step button and show submit button if current step is last step
    $nextStepBtn.setAttribute('aria-hidden', swiperInstance.isEnd);
    $submitBtn.setAttribute('aria-hidden', !swiperInstance.isEnd);

    // Disable next step and submit buttons if current step has options and none is checked
    $nextStepBtn.setAttribute('aria-disabled', !nextBtnEnabled);
    $submitBtn.setAttribute('aria-disabled', !nextBtnEnabled);
  };

  /**
   * Checks if all required options are selected
   * @param {*} $stepOptions - Dom element of options wrapper
   * @returns {Boolean} - True if has all required options selected
   */
  const hasAllRequiredOptionsSelected = $stepOptions => {
    if (!$stepOptions) return false;

    const preferenceBlocks = $stepOptions.querySelectorAll('.js-preference-options-block');

    if (!preferenceBlocks.length) {
      // If there's no preference block then there's only a block of options
      return hasOptionSelected($stepOptions);
    } else {
      // Each preference block must have at least an option selected
      return [...preferenceBlocks].every(hasOptionSelected);
    }
  };

  /**
   * Checks if there is options selected
   * @param {*} $stepOptions - Dom element of options wrapper
   * @returns {Boolean} - True if has options selected
   */
  const hasOptionSelected = $stepOptions => {
    if (!$stepOptions) return false;

    const options = $stepOptions.querySelectorAll(OPTION_SELECTOR);

    return [...options].some(option => option.checked);
  };

  const sendStepUtagEvent = ({ category = 'onboarding', step, label }) => {
    if (!step || !label) return;
    let event_step = `step ${step}`;
    let action = '';
    IB.utag_events.sendUtagEvent({
      data: {
        event_name: '',
        event_cat: category,
        step: event_step,
        event_act: action,
        event_lbl: label,
        event_purpose: '',
        event_structure: '',
      },
    });
  };

  /**
   * Checks next step button state when an option has changed
   */
  $stepper.querySelectorAll(OPTION_SELECTOR).forEach(option => {
    option.addEventListener('change', () => {
      handlePaginationButtonState();

      if (option.checked) {
        const eventAction = option.closest('[data-event-action]')?.dataset.eventAction;
        const eventLabel = option.dataset.eventLabel;

        if (!eventAction || !eventLabel) return;

        IB.utag_events.sendUtagEvent({
          data: {
            event_name: '',
            event_cat: 'onboarding',
            event_act: eventAction,
            event_lbl: eventLabel,
            event_purpose: '',
            event_structure: '',
          },
        });
      }
    });
  });

  const swiperInstance = new Swiper(stepperSelector, {
    spaceBetween: 0,
    slidesPerView: 1,
    allowTouchMove: false,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
    },
    pagination: {
      el: '.b-onboarding__pagination',
      type: 'custom',
      renderCustom: (swiper, current, total) => paginationFormat.replace('{{count}}', current).replace('{{total}}', total),
    },
    navigation: {
      nextEl: '.js-swiper-button-next',
      prevEl: '.js-swiper-button-prev',
    },
    on: {
      init: () => {
        sendStepUtagEvent({ step: $stepper.swiper.activeIndex + 1, label: 'view' });
      },
      slidePrevTransitionStart: () => {
        sendStepUtagEvent({ step: $stepper.swiper.activeIndex + 2, label: 'previous' });
      },
      slideNextTransitionStart: () => {
        sendStepUtagEvent({ step: $stepper.swiper.activeIndex, label: 'next' });
      },
      slideChange: () => {
        handlePaginationButtonState();
      },
      slideChangeTransitionEnd: () => {
        sendStepUtagEvent({ step: $stepper.swiper.activeIndex + 1, label: 'view' });
      },
    },
  });

  return swiperInstance;
};
